// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.selectable
{
    font-weight: bold;
}

.selected td
{
    background-color: #ffba75 !important;
}`, "",{"version":3,"sources":["webpack://./src/app/ui/product-group-selector/product-group-selector.component.css"],"names":[],"mappings":"AAAA;;IAEI,iBAAiB;AACrB;;AAEA;;IAEI,oCAAoC;AACxC","sourcesContent":[".selectable\r\n{\r\n    font-weight: bold;\r\n}\r\n\r\n.selected td\r\n{\r\n    background-color: #ffba75 !important;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
