import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';


@Injectable()
export class SettingsService {
  readonly restBaseUrl: string = location.protocol + '/rest/';
  public sessionId: string;
  public tinyMceInit;
  public tinyMceMenuItems;
  public httpOptions: { headers: any };
  public data: any;
  public right: any;
  public user: { name: string, value: any, permanent: any; }[] = [];
  public docsearchcustomer = '';
  public notificationManager: any;
  public calendar;

  // Development settings
  public dev_mode =  true;
  public appVersion = 'v240409'

  constructor(public http: HttpClient) {
    this.sessionId = (window as any).session_id;
    this.docsearchcustomer = (window as any).docsearchcustomer;
    if ( this.docsearchcustomer === '0' ) { this.docsearchcustomer = ''; }
    this.httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', token: this.sessionId }) };
  }

  initSettings() {
    if(this.sessionId !== '') {
      this.setupTinyMce();
      this.loadData();
      return this.loadSettings();
    }
  }

  private loadSettings() {

    this.http.post<any[]>( this.restBaseUrl + 'user/settings/', {} , this.httpOptions )
        .subscribe({
              next: (data) => {
                this.user = data;
              },
              error: (error) => {
                console.log(error);
              }
            }
        );
  }

  public loadData() {
    this.http.get<any[]>( this.restBaseUrl + 'user/data/' , this.httpOptions )
        .subscribe({
              next: (data) => {
                this.data = data;
              },
              error: (error) => {
                console.log(error);
              }
            }
        );
  }

  public setupTinyMce() {
    this.tinyMceMenuItems = '';
    this.http.post<any[]>(this.restBaseUrl + 'textblock', { currentUser : 'true' , showGlobal : 'true' }, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          let functionContent = '';
          let counter = 1;
          // @ts-ignore
          data.data.forEach(obj => {
            this.tinyMceMenuItems += 'menu' + counter + ' ';
            functionContent += 'editor.ui.registry.addMenuItem(\'menu' + counter++ + '\', {text: \'';
            if (obj.fk_user === '0') {
              functionContent += 'Firma: ';
            }
            functionContent += obj.name + '\', onAction: (_) => editor.insertContent(\'' + obj.text + '\') });\n';
          });
          this.createTinyMceInit();
          const adder = new Function('editor', functionContent);
          this.tinyMceInit.setup = adder;
        }
    );
  }

  public createTinyMceInit() {
    this.tinyMceInit = {
      forced_root_block: false,
      force_br_newlines: true,
      force_p_newlines: false,
      contextmenu: false,
      content_css : 'assets/tinymce.css',
      plugins: [
        'tabfocus advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen noneditable',
        'insertdatetime media table paste'
      ],
      tabfocus_elements: ':prev,:next',
      toolbar_items_size: 'small',
      menubar: ' edit view format table tools custom', // file insert tools
      menu: {
        file:   {title: 'File', items: 'newdocument'},
        edit:   {title: 'Edit', items: 'undo redo | cut copy paste pastetext | selectall'},
        insert: {title: 'Insert', items: 'link media | template hr'},
        view:   {title: 'View', items: 'visualaid'},
        format: {title: 'Format', items: 'bold italic underline strikethrough superscript subscript | formats | removeformat'},
        table:  {title: 'Table', items: 'inserttable tableprops deletetable | cell row column'},
        tools:  {title: 'Tools', items: 'code'},
        custom: {title: 'Textbausteine', items: this.tinyMceMenuItems}
      },
      toolbar:
          'insertfile undo redo | styleselect | removeformat  | bold underline italic forecolor  | alignleft aligncenter alignright alignjustify | bullist numlist | link '
    };
  }

  set(name: string, value: any, permanent: any) {
    const index = this.user.findIndex(((obj: { name: string, value: any, permanent: any; }) => obj.name === name));
    if (index === -1) {
      this.user.push({ name, value, permanent });
    } else {
      this.user[index].value = value;
    }
    this.http.put<any[]>(this.restBaseUrl + 'user/settings/', { name, value, permanent }, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
        }
    );
  }

  get(name: string) {
    const index = this.user.findIndex((obj => obj.name === name));
    if (index === -1) { return false; } else { return this.user[index].value; }
  }
}
