// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.calendarOverlay
{
    position: fixed;
    visibility: hidden;
    top:0px; bottom:0px;
}

.calendarOverlayOpen
{
    overflow:hidden;
    visibility:visible;
    top:0px;
    left:0px;
    right:0px;
    bottom:0px;
    position: fixed;
    z-index: 998;
    background-color: rgb(73, 126, 220)
}

.grantedList {
    overflow: auto;
}

.grantedList div{
    height:20px;
}

.smsButton {
    height: 18px;float: right;font-size: 11px;margin-top: 2px;margin-right: 4px;border: 1px solid #878787;
}

.smsButtonSelected {
    background-color: #cdffb3;border: 1px solid #878787;
}

.smsButtonSelected:hover {
    background-color: #cdffb3 !important; border: 1px solid #5a5a5a !important;
}`, "",{"version":3,"sources":["webpack://./src/app/ui/calendar/calendar.component.css"],"names":[],"mappings":"AAAA;;IAEI,eAAe;IACf,kBAAkB;IAClB,OAAO,EAAE,UAAU;AACvB;;AAEA;;IAEI,eAAe;IACf,kBAAkB;IAClB,OAAO;IACP,QAAQ;IACR,SAAS;IACT,UAAU;IACV,eAAe;IACf,YAAY;IACZ;AACJ;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,YAAY,CAAC,YAAY,CAAC,eAAe,CAAC,eAAe,CAAC,iBAAiB,CAAC,yBAAyB;AACzG;;AAEA;IACI,yBAAyB,CAAC,yBAAyB;AACvD;;AAEA;IACI,oCAAoC,EAAE,oCAAoC;AAC9E","sourcesContent":[".calendarOverlay\r\n{\r\n    position: fixed;\r\n    visibility: hidden;\r\n    top:0px; bottom:0px;\r\n}\r\n\r\n.calendarOverlayOpen\r\n{\r\n    overflow:hidden;\r\n    visibility:visible;\r\n    top:0px;\r\n    left:0px;\r\n    right:0px;\r\n    bottom:0px;\r\n    position: fixed;\r\n    z-index: 998;\r\n    background-color: rgb(73, 126, 220)\r\n}\r\n\r\n.grantedList {\r\n    overflow: auto;\r\n}\r\n\r\n.grantedList div{\r\n    height:20px;\r\n}\r\n\r\n.smsButton {\r\n    height: 18px;float: right;font-size: 11px;margin-top: 2px;margin-right: 4px;border: 1px solid #878787;\r\n}\r\n\r\n.smsButtonSelected {\r\n    background-color: #cdffb3;border: 1px solid #878787;\r\n}\r\n\r\n.smsButtonSelected:hover {\r\n    background-color: #cdffb3 !important; border: 1px solid #5a5a5a !important;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
