// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
    display: inline-block;
}

.suggest-dropdown-long
{
    width: 100%;
    height:20px;
    font-size: 12px;
    line-height: 21px;
    vertical-align: bottom;
    border:1px solid #ceced2;
    border-right: 3px #ffffff;
    -webkit-border-top-left-radius: 3px;
    -webkit-border-bottom-left-radius: 3px;
    -moz-border-radius-topleft: 3px;
    -moz-border-radius-bottomleft: 3px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;z-index: 10;
}

.suggest-dropdown-short
{
    width: 100%;
    height:20px;
    font-size: 12px;
    line-height: 21px;
    vertical-align: bottom;
    border:1px solid #ceced2;
    border-right: 3px #ffffff;
    -webkit-border-top-left-radius: 3px;
    -webkit-border-bottom-left-radius: 3px;
    -moz-border-radius-topleft: 3px;
    -moz-border-radius-bottomleft: 3px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;z-index: 10;
}

.dropdownDisplaySpan {
    display: inline-block;
    margin-top:2px;
    margin-left: 2px;
}
`, "",{"version":3,"sources":["webpack://./src/app/ui/dropdown/dropdown.component.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;AACzB;;AAEA;;IAEI,WAAW;IACX,WAAW;IACX,eAAe;IACf,iBAAiB;IACjB,sBAAsB;IACtB,wBAAwB;IACxB,yBAAyB;IACzB,mCAAmC;IACnC,sCAAsC;IACtC,+BAA+B;IAC/B,kCAAkC;IAClC,2BAA2B;IAC3B,8BAA8B,CAAC,WAAW;AAC9C;;AAEA;;IAEI,WAAW;IACX,WAAW;IACX,eAAe;IACf,iBAAiB;IACjB,sBAAsB;IACtB,wBAAwB;IACxB,yBAAyB;IACzB,mCAAmC;IACnC,sCAAsC;IACtC,+BAA+B;IAC/B,kCAAkC;IAClC,2BAA2B;IAC3B,8BAA8B,CAAC,WAAW;AAC9C;;AAEA;IACI,qBAAqB;IACrB,cAAc;IACd,gBAAgB;AACpB","sourcesContent":[":host {\r\n    display: inline-block;\r\n}\r\n\r\n.suggest-dropdown-long\r\n{\r\n    width: 100%;\r\n    height:20px;\r\n    font-size: 12px;\r\n    line-height: 21px;\r\n    vertical-align: bottom;\r\n    border:1px solid #ceced2;\r\n    border-right: 3px #ffffff;\r\n    -webkit-border-top-left-radius: 3px;\r\n    -webkit-border-bottom-left-radius: 3px;\r\n    -moz-border-radius-topleft: 3px;\r\n    -moz-border-radius-bottomleft: 3px;\r\n    border-top-left-radius: 3px;\r\n    border-bottom-left-radius: 3px;z-index: 10;\r\n}\r\n\r\n.suggest-dropdown-short\r\n{\r\n    width: 100%;\r\n    height:20px;\r\n    font-size: 12px;\r\n    line-height: 21px;\r\n    vertical-align: bottom;\r\n    border:1px solid #ceced2;\r\n    border-right: 3px #ffffff;\r\n    -webkit-border-top-left-radius: 3px;\r\n    -webkit-border-bottom-left-radius: 3px;\r\n    -moz-border-radius-topleft: 3px;\r\n    -moz-border-radius-bottomleft: 3px;\r\n    border-top-left-radius: 3px;\r\n    border-bottom-left-radius: 3px;z-index: 10;\r\n}\r\n\r\n.dropdownDisplaySpan {\r\n    display: inline-block;\r\n    margin-top:2px;\r\n    margin-left: 2px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
