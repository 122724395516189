// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tutorialContentBox{
    position: absolute;
    z-index: 99999999;
}

.tutorialContentBox .tutorialContent{
    border-radius: 6px;

    width:300px;
    height:-moz-fit-content;
    height:fit-content;
    background: rgba(255, 255, 255, 1);
    z-index: 999999;
    display: inline-block;
}

.tutorialContentBox .leftArrow{
    font-size: 30px;
    display: inline-block;
    color: white;
    margin-right: -7px;
    vertical-align: top;
}
`, "",{"version":3,"sources":["webpack://./src/app/ui/tutorial/tutorial.component.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IAGI,kBAAkB;;IAElB,WAAW;IACX,uBAAkB;IAAlB,kBAAkB;IAClB,kCAAkC;IAClC,eAAe;IACf,qBAAqB;AACzB;;AAEA;IACI,eAAe;IACf,qBAAqB;IACrB,YAAY;IACZ,kBAAkB;IAClB,mBAAmB;AACvB","sourcesContent":[".tutorialContentBox{\r\n    position: absolute;\r\n    z-index: 99999999;\r\n}\r\n\r\n.tutorialContentBox .tutorialContent{\r\n    -webkit-border-radius: 6px;\r\n    -moz-border-radius: 6px;\r\n    border-radius: 6px;\r\n\r\n    width:300px;\r\n    height:fit-content;\r\n    background: rgba(255, 255, 255, 1);\r\n    z-index: 999999;\r\n    display: inline-block;\r\n}\r\n\r\n.tutorialContentBox .leftArrow{\r\n    font-size: 30px;\r\n    display: inline-block;\r\n    color: white;\r\n    margin-right: -7px;\r\n    vertical-align: top;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
