// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.attachmentMenuButton {
    display: inline-block;
    vertical-align: top;
    height: 28px;
}

.attachmentMenuButton:hover {
    background-color: orange;
}
`, "",{"version":3,"sources":["webpack://./src/app/ui/attachment-menu/attachment-menu.component.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,wBAAwB;AAC5B","sourcesContent":[".attachmentMenuButton {\r\n    display: inline-block;\r\n    vertical-align: top;\r\n    height: 28px;\r\n}\r\n\r\n.attachmentMenuButton:hover {\r\n    background-color: orange;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
