// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card .title
{
    display: block;
    margin-top: 3px;
    margin-bottom: 3px;
    font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/app/ui/process-timeline/process-timeline.component.css"],"names":[],"mappings":"AAAA;;IAEI,cAAc;IACd,eAAe;IACf,kBAAkB;IAClB,iBAAiB;AACrB","sourcesContent":[".card .title\r\n{\r\n    display: block;\r\n    margin-top: 3px;\r\n    margin-bottom: 3px;\r\n    font-weight: bold;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
