import { Component, Input, Output, OnInit, OnChanges, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { SettingsService } from '../../services/settings.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  // Die untere Struktur aktiviert das 2 Wege Databinding
  // im Element muss die externe Variable so zugewiesen werden: [(model)]="variable"
  // um die Variablen Änderung nach Außen zu kommunizieren muss wie in der unten stehenden
  // Methode changeModel() die Methode this.modelChange.emit('VALUE');


  public overlayId: string;
  public OverlayActive = false;
  public moduleOpen = null;
  public currentModule;

  public menuStructure: any;
  public projects: any;
  public expandedMenu: any;

  public menuLast: any;
  public menuLinks: any;

  public currentLast: any;
  public currentLinks: any;

  public moduleName: any;

  public currentSelectedTab = 1;
  public menuDescriptionText: any;


  constructor(public http: HttpClient, public settings: SettingsService, public router: Router) {
  this.expandedMenu = '';
    this.http.post<any[]>(this.settings.restBaseUrl + 'ui/menu', {}, this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.menuStructure = data;
        }
    );
    this.http.post<any[]>(this.settings.restBaseUrl + 'ui/menu/project', {}, this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.projects = data;
        }
    );
    this.menuLinks = [];
    this.menuLast = [];
  }

  ngOnInit() {
    const dateNow = new Date();
    this.overlayId = + dateNow + '_' + Math.floor((Math.random() * 9999) + 1000);
  }

  activateOverlay(): void {
    this.OverlayActive = true;
    document.getElementById(this.overlayId).style.marginLeft =  '-312px';
    document.getElementById(this.overlayId).style.width = '800px';
    document.getElementById(this.overlayId).style.marginTop = '5px';
    document.getElementById(this.overlayId).style.visibility = 'visible';
  }

  deactivateOverlay(): void {
    document.getElementById(this.overlayId).style.visibility = 'hidden';
    this.OverlayActive = false;
    this.moduleOpen = null;
    this.expandedMenu = '';
  }

  toggleOverlay(module) {
    if ( this.moduleOpen === null ) {
      this.moduleOpen = module;
      this.activateOverlay();
      return;
    } else {
      this.deactivateOverlay();
    }
  }

  expandMenu(modul): void {
    if(modul !== '' && modul.menuPoints.length<4) return;
    this.expandedMenu = modul;
  }

  changeTabButton(tabId): void {
    this.currentSelectedTab = tabId;
  }

  public action(actionNumber) {
    this.deactivateOverlay();
    if (actionNumber === 1) { this.router.navigate(['/process' , '-1']); }
    if (actionNumber === 2) { this.router.navigate(['/process' , '-2']); }
    if (actionNumber === 3) {
      this.router.navigate(['/core-frame' , encodeURIComponent('../retirement_home_contract/bewohner/bewohner_list.php?new=1&session_id=' + this.settings.sessionId )]);
    }
  }

  public callMenuUrl(item,newTab) {
    this.deactivateOverlay();
    let params = [];
    if (item.url === '') {
      params = item.path.split('/');
      params[1] = '/' + params[1];
      params.shift();
    } else {
      params = ['/core-frame' , encodeURIComponent(item.url + '?session_id=' + this.settings.sessionId + item.path)];
    }

    if(newTab) {
      const getUrl = window.location;
      let loadUrl = getUrl .protocol + '//' + getUrl.host + getUrl.pathname + '?session_id=' + this.settings.sessionId;
      if (item.url === '') {
        loadUrl += item.path;
      } else {
        loadUrl += '#/core-frame/' + encodeURIComponent(encodeURIComponent(item.url + '?session_id=' + this.settings.sessionId + item.path));
      }
      window.open(loadUrl, '_blank');
    } else{
      if (item.url === '') {
        params = item.path.split('/');
        params[1] = '/' + params[1];
        params.shift();
      } else {
        params = ['/core-frame' , encodeURIComponent(item.url + '?session_id=' + this.settings.sessionId + item.path)];
      }
      this.router.navigate(params);
    }
  }

  loadProcess(process: any) {
    this.deactivateOverlay();
    if ( process.mdi > 0) {
      this.router.navigate(['/process' , process.id, 'edit']);
    } else {
      this.router.navigate(['/process' , process.tid, 'load']);
    }
  }

  mouseEnter(y) {
    if(y==='') {
      this.menuDescriptionText = '';
    }else {
      this.menuDescriptionText = y.description;
    }
  }
}
