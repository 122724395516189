// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.resizerIframe {
    width:100%;
    border: none;
    padding: 0;
    margin: 0;
}
`, "",{"version":3,"sources":["webpack://./src/app/ui/resize-iframe/resize-iframe.component.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,YAAY;IACZ,UAAU;IACV,SAAS;AACb","sourcesContent":[".resizerIframe {\r\n    width:100%;\r\n    border: none;\r\n    padding: 0;\r\n    margin: 0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
