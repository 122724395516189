import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inline-text-editor',
  templateUrl: './inline-text-editor.component.html',
  styleUrls: ['./inline-text-editor.component.css']
})
export class InlineTextEditorComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
