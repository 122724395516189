import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProcessComponent } from './process/process/process.component';
import { LoginComponent } from './ui/login/login.component';
import { UrlframeComponent } from './ui/urlframe/urlframe.component';
import {CompanyManagementComponent} from './admin-base/company-management/company-management.component';
import {CoreBaseDataManagementComponent} from './admin-base/core-base-data-management/core-base-data-management.component';
import {
  CoreRightModulUserRightComponent
} from './ui/core-right-modul-user-right/core-right-modul-user-right.component';

const routes: Routes = [
  { path: '', component: ProcessComponent, pathMatch: 'full'},

  { path: 'system',             loadChildren: () => import('./system/system.module').then(m => m.SystemModule) },

  { path: 'process',            loadChildren: () => import('./process/process.module').then(m => m.ProcessModule) },
  { path: 'document',           loadChildren: () => import('./document/document.module').then(m => m.DocumentModule) },
  { path: 'erp',                loadChildren: () => import('./erp/erp.module').then(m => m.ErpModule) },
  { path: 'time-tracking',      loadChildren: () => import('./time-tracking/time-tracking.module').then(m => m.TimeTrackingModule) },
  { path: 'information',        loadChildren: () => import('./information/information.module').then(m => m.InformationModule) },
  { path: 'whistleblower',      loadChildren: () => import('./whistleblower/whistleblower.module').then(m => m.WhistleblowerModule) },
  { path: 'hardware',           loadChildren: () => import('./hardware/hardware.module').then(m => m.HardwareModule) },

  { path: 'admin-base',         loadChildren: () => import('./admin-base/admin-base.module').then(m => m.AdminBaseModule) },
  { path: 'admin-process',      loadChildren: () => import('./admin-process/admin-process.module').then(m => m.AdminProcessModule) },
  { path: 'admin-document',     loadChildren: () => import('./admin-document/admin-document.module').then(m => m.AdminDocumentModule) },
  { path: 'admin-erp',          loadChildren: () => import('./admin-erp/admin-erp.module').then(m => m.AdminErpModule) },
  { path: 'admin-inventory',    loadChildren: () => import('./admin-inventory/admin-inventory.module').then(m => m.AdminInventoryModule) },
  { path: 'admin-workflow',     loadChildren: () => import('./admin-workflow/admin-workflow.module').then(m => m.AdminWorkflowModule) },
  { path: 'admin-system',       loadChildren: () => import('./admin-system/admin-system.module').then(m => m.AdminSystemModule) },

  { path: 'customer',           loadChildren: () => import('./customer/customer.module').then(m => m.CustomerModule) },

  { path: 'login/:hash', component: LoginComponent},
  { path: 'login', component: LoginComponent},
  { path: 'core-frame/:url', component: UrlframeComponent},


  { path: 't1', component: CompanyManagementComponent},
  { path: 't2', component: CoreBaseDataManagementComponent},
  { path: 't3', component: CoreRightModulUserRightComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
