import {Component, Input, OnInit, OnChanges, EventEmitter, Output} from '@angular/core';
import {map} from 'rxjs/operators';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {SettingsService} from '../../services/settings.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-suggest-text-input',
  templateUrl: './suggest-text-input.component.html',
  styleUrls: ['./suggest-text-input.component.css']
})
export class SuggestTextInputComponent implements OnInit, OnChanges {
  overlayId: any;
  OverlayActive = false;
  data: any[];
  searchText: any;
  disabled: any;
  markedWithKeyboard = 0;
  public displayMode: any;
  public selectedValue: any;
  public httpOptions: { };

  @Output() onChange = new EventEmitter<boolean>();
  @Input() endpoint: string;
  @Input() settabindex: any;
  @Input() mark: boolean;
  @Output() modelChange: EventEmitter<string>;
  public selectedItem: any;

  @Input()
  get model() {
    return this.selectedValue;
  }
  set model(value: string) {
    this.ngOnChanges(value);
  }

  constructor(public http: HttpClient, public settings: SettingsService) {
    if ( this.mark === undefined ) { this.mark = false; }
    this.modelChange = new EventEmitter();
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        token: settings.sessionId
      })
    };
  }

  ngOnInit() {
    const dateNow = new Date();
    this.overlayId = + dateNow + '_' + Math.floor((Math.random() * 9999) + 1000);
  }

  ngOnChanges(value) {
    if ( typeof value !== 'string' ) { return; }
    this.searchText = '';
    if (value !== undefined && value != null && value !== '0' && value != '') {
      this.selectedValue = value;
      this.http.get<{name: ''}>( this.settings.restBaseUrl + this.endpoint + '/' + this.selectedValue, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
            this.searchText = data.name;
          });
    }
  }

  clear() {
    this.searchText = '';
  }

  select(item: any) {
    this.selectedItem = item;
    if (item.id !== '') { this.selectedValue = item.id; } else { this.selectedValue = item.name; }
    this.modelChange.emit(this.selectedValue);
    this.searchText = item.name;
    this.deactivateOverlay();
    this.onChange.emit(null);
  }

  search($event: KeyboardEvent) {
      if($event !== null) {
        if( $event.key === 'Enter' && this.searchText.length === 0 ) {
          this.selectedValue = ''
          this.modelChange.emit(this.selectedValue);
          this.searchText = '';
          this.markedWithKeyboard = 0;
          this.deactivateOverlay();
          setTimeout(() => {
            document.getElementById(this.overlayId+'TextSuggestBox').blur();
          }, 100);
          return;
        } else if ($event.key === 'Enter' && this.searchText.length > 0 && this.data.length > 0) {
          this.select(this.data[this.markedWithKeyboard]);
          setTimeout(() => {
            document.getElementById(this.overlayId+'TextSuggestBox').blur();
          }, 100);
          return;
        } else if ($event.key === 'ArrowDown' && this.data.length > 0) {
            if( this.markedWithKeyboard + 1 <= this.data.length) {
              this.markedWithKeyboard ++;
            }
        } else if ($event.key === 'ArrowUp' && this.data.length > 0) {
          if( this.markedWithKeyboard - 1 >= 0) {
            this.markedWithKeyboard --;
          }
        } else if (this.data == undefined || this.data.length == 0) {
          this.deactivateOverlay();
        }
    }
    console.log('search');

    this.selectedValue = 0;
      this.http.post<{ status, message, data }>( this.settings.restBaseUrl + this.endpoint, { text: this.searchText, newdata:1 }, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
            this.data = data.data;
            this.activateOverlay();
          });
  }

  checkExistence() {
    this.markedWithKeyboard = 0;
    this.deactivateOverlay();
  }

  activateOverlay(): void {
    this.OverlayActive = true;
    document.getElementById(this.overlayId).style.width = (document.getElementById(this.overlayId + 'TextSuggestBox').clientWidth+7)+'px';
    document.getElementById(this.overlayId).style.visibility = 'visible';
  }

  deactivateOverlay(): void {
    this.markedWithKeyboard = 0;
    this.OverlayActive = false;
    setTimeout(() => {
      document.getElementById(this.overlayId).style.visibility = 'hidden';
    }, 100);
  }

  openSelectWindow() {
    this.searchText = '';
    this.search(null);
    this.toggleOverlay();
  }

  toggleOverlay() {
    if (this.OverlayActive === false) { this.activateOverlay(); } else { this.deactivateOverlay(); }
  }
}
