// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tree
{
    font-size: 1.2em;
    margin: 0px;
    padding-inline-start: 0px;
    margin-top: 2px;
    display: block;
    overflow: auto;
}

.tree ul, .tree li
{
    list-style: none;
    padding-inline-start: 10px;
}

.tree .open
{
    float: left;
    width: 20px;
    height: 1.2em;
    font-size: 1.2em;
    text-align: center;
    margin-top: -1px;
    font-weight: bold;
    vertical-align: baseline;
    display: inline-block !important;
    margin-left:4px;
}

.tree .node
{
    width: 80%;
    height: 1.2em;
    display: inline-block !important;
}

.tree .row:hover
{
    background-color: #ffba75;
}

.tree .row
{
    width: 90%;
    margin-left: 10px;
    display: inline-block;
}

.tree .selectable
{
    font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/app/ui/tree-view/tree-view.component.css"],"names":[],"mappings":"AAAA;;IAEI,gBAAgB;IAChB,WAAW;IACX,yBAAyB;IACzB,eAAe;IACf,cAAc;IACd,cAAc;AAClB;;AAEA;;IAEI,gBAAgB;IAChB,0BAA0B;AAC9B;;AAEA;;IAEI,WAAW;IACX,WAAW;IACX,aAAa;IACb,gBAAgB;IAChB,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,wBAAwB;IACxB,gCAAgC;IAChC,eAAe;AACnB;;AAEA;;IAEI,UAAU;IACV,aAAa;IACb,gCAAgC;AACpC;;AAEA;;IAEI,yBAAyB;AAC7B;;AAEA;;IAEI,UAAU;IACV,iBAAiB;IACjB,qBAAqB;AACzB;;AAEA;;IAEI,iBAAiB;AACrB","sourcesContent":[".tree\r\n{\r\n    font-size: 1.2em;\r\n    margin: 0px;\r\n    padding-inline-start: 0px;\r\n    margin-top: 2px;\r\n    display: block;\r\n    overflow: auto;\r\n}\r\n\r\n.tree ul, .tree li\r\n{\r\n    list-style: none;\r\n    padding-inline-start: 10px;\r\n}\r\n\r\n.tree .open\r\n{\r\n    float: left;\r\n    width: 20px;\r\n    height: 1.2em;\r\n    font-size: 1.2em;\r\n    text-align: center;\r\n    margin-top: -1px;\r\n    font-weight: bold;\r\n    vertical-align: baseline;\r\n    display: inline-block !important;\r\n    margin-left:4px;\r\n}\r\n\r\n.tree .node\r\n{\r\n    width: 80%;\r\n    height: 1.2em;\r\n    display: inline-block !important;\r\n}\r\n\r\n.tree .row:hover\r\n{\r\n    background-color: #ffba75;\r\n}\r\n\r\n.tree .row\r\n{\r\n    width: 90%;\r\n    margin-left: 10px;\r\n    display: inline-block;\r\n}\r\n\r\n.tree .selectable\r\n{\r\n    font-weight: bold;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
