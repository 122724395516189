import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { SettingsService } from './services/settings.service';
import { ContextMenuModule } from '@perfectmemory/ngx-contextmenu';
import { AngularSplitModule } from 'angular-split';
import { UiModule } from './ui/ui.module';
import { EditorModule } from '@tinymce/tinymce-angular';
import { TutorialService } from './services/tutorial.service';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import {LOCALE_ID} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
registerLocaleData(localeDe);

const settingsInit = (settings: SettingsService) => {
    return() => {
        return settings.initSettings();
    };
};

@NgModule({
  declarations: [
    AppComponent
  ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        EditorModule,
        ContextMenuModule,
        AngularSplitModule,
        UiModule
    ],
    providers: [    TutorialService,
                    SettingsService,
                    {provide: LOCALE_ID, useValue: 'de'}, {
                        provide: APP_INITIALIZER,
                        useFactory: settingsInit,
                        multi: true,
                        deps: [SettingsService]
                    }],
    bootstrap: [AppComponent]
})
export class AppModule { }
