// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.optionCheckbox {
    height: -moz-fit-content;
    height: fit-content;
    border-bottom: 1px solid lightgrey;
}

.optionCheckbox:hover {
    background-color: #dbdbdb;
}

.optionCheckbox div {
    padding:5px;
}
`, "",{"version":3,"sources":["webpack://./src/app/ui/options/options.component.css"],"names":[],"mappings":"AAAA;IACI,wBAAmB;IAAnB,mBAAmB;IACnB,kCAAkC;AACtC;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,WAAW;AACf","sourcesContent":[".optionCheckbox {\r\n    height: fit-content;\r\n    border-bottom: 1px solid lightgrey;\r\n}\r\n\r\n.optionCheckbox:hover {\r\n    background-color: #dbdbdb;\r\n}\r\n\r\n.optionCheckbox div {\r\n    padding:5px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
