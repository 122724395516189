import { Component, OnInit, ViewChild, HostListener  } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SettingsService } from '../../services/settings.service';
import { trigger, style, transition, animate } from '@angular/animations';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from 'src/app/_modal';

@Component({
  selector:     'app-login',
  templateUrl:  './login.component.html',
  styleUrls:   ['./login.component.css'],
  animations: [
    trigger('fadeIn', [
      transition (':enter', [
          style({ opacity: 0}),
          animate('200ms ease-in', style({ opacity: 1 }))
      ])
    ])
  ]
})

export class LoginComponent implements OnInit {
    public databaseUpdate;     // todo: Implement and test
    public billid;             // todo: Implement and test
    public copyrightDate;
    public loginData = { username: '', password: '' };
    public pwr;
    public userToResetPassword: string;
    public newPassword1: string;
    public newPassword2: any;
    public newPasswordCheck = { length : false, special: false, upper: false, lower: false, digits: false, same: false };
    public loginPasswordCheck = { length : false, special: false, upper: false, lower: false, digits: false };
    public hash: any;
    public messageContent = '';
    public dbNeedUpdates = false;
    public changePassword = false;
    public capsActive = false;
    public maintenance = false;

    @HostListener('document:keydown ', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        if (event.getModifierState('CapsLock') ) {
            this.capsActive = true;
        } else { this.capsActive = false; }

        if (this.messageContent === '') { return; }
        if (event.key === 'Escape' || event.key === 'Enter') {
            this.closeMessageBox();
        }
    }

    constructor(public http: HttpClient, public settings: SettingsService, public route: ActivatedRoute, public message: ModalService, public activeRoute: ActivatedRoute) {
        this.http.get<{ status, message, dbUpdateNeeded, maintenance }>(this.settings.restBaseUrl + 'ui/systemstate' , this.settings.httpOptions)
            .pipe(map(data => data)).subscribe(
            (data => {
                    if (data.status === 'error') {
                        if(data.dbUpdateNeeded) {
                            this.dbNeedUpdates = true;
                        } else if(data.maintenance==='1') {
                            this.maintenance = true;
                        } else {
                            alert(data.message);
                        }
                    }
                }
            ));
        this.pwr = '';
        this.hash = (window as any).hash;
        if ( this.hash.length > 1 ) {
          this.http.get<{ status: string, message: string }>(this.settings.restBaseUrl + 'user/password/reset/' + this.hash, this.settings.httpOptions)
              .pipe(map(data => data)).subscribe(
              (data => {
                    if (data.status === 'success') {
                      this.changePwr(3);
                    } else {
                        this.hash = '';
                        this.messageContent = 'Der Link ist abgelaufen. Bitte starten Sie den Vorgang erneut oder wenden Sie sich an Ihren Systembetreuer.';
                        this.message.open('messageBox');
                    }
                  }
              ));
        }
        this.databaseUpdate = false;
        this.copyrightDate = new Date();
        // @ts-ignore
        // tslint:disable-next-line:triple-equals
        if ( nick != '' ) {
            // @ts-ignore
            // Externer Aufruf mit Nick in URL
            this.loginData.username = nick;
        }
    }

    ngOnInit() {
        // @ts-ignore
        if ( nick !== '' ) { this.loginData.username = nick; }
        // @ts-ignore
        if ( docsearchcustomer !== '' ) { this.settings.docsearchcustomer = docsearchcustomer; }
        setTimeout(() => {
            if(this.loginData.username == '') {
                if (document.getElementById('username') !== undefined) {
                    document.getElementById('username').focus();
                }
            } else {
                if (document.getElementById('password') !== undefined) {
                    document.getElementById('password').focus();
                }
            }
        }, 20);
    }

    checkLoginPassword() {
        if (this.loginData.password.length >= 8) { this.loginPasswordCheck.length = true; } else { this.loginPasswordCheck.length = false; }
        if (this.loginData.password.replace(/[^\W]/g, '').length >= 1) { this.loginPasswordCheck.special = true; } else { this.loginPasswordCheck.special = false; }
        if (this.loginData.password.replace(/[^A-Z]/g, '').length >= 1) { this.loginPasswordCheck.upper = true; } else { this.loginPasswordCheck.upper = false; }
        if (this.loginData.password.replace(/[^a-z]/g, '').length >= 1) { this.loginPasswordCheck.lower = true; } else { this.loginPasswordCheck.lower = false; }
        if (this.loginData.password.replace(/\D/g, '').length >= 2) { this.loginPasswordCheck.digits = true; } else { this.loginPasswordCheck.digits = false; }
    }

    checkPassword() {
        if (this.newPassword1.length >= 8) { this.newPasswordCheck.length = true; } else { this.newPasswordCheck.length = false; }
        if (this.newPassword1.replace(/[^\W]/g, '').length >= 1) { this.newPasswordCheck.special = true; } else { this.newPasswordCheck.special = false; }
        if (this.newPassword1.replace(/[^A-Z]/g, '').length >= 1) { this.newPasswordCheck.upper = true; } else { this.newPasswordCheck.upper = false; }
        if (this.newPassword1.replace(/[^a-z]/g, '').length >= 1) { this.newPasswordCheck.lower = true; } else { this.newPasswordCheck.lower = false; }
        if (this.newPassword1.replace(/\D/g, '').length >= 2) { this.newPasswordCheck.digits = true; } else { this.newPasswordCheck.digits = false; }
        if (this.newPassword1 === this.newPassword2) { this.newPasswordCheck.same = true; } else { this.newPasswordCheck.same = false; }
    }

    saveNewPassword() {
    if (!(this.newPasswordCheck.length && this.newPasswordCheck.special && this.newPasswordCheck.upper && this.newPasswordCheck.lower && this.newPasswordCheck.digits && this.newPasswordCheck.same)) {
        this.messageContent = 'Das Passwort entspreicht nicht den Komplexätitsregeln. Es müssen alle Punkte grün Markiert sein!';
        this.message.open('messageBox');
        return;
    }
    if (this.changePassword) {
        this.http.put<{status: string, message: string}>(this.settings.restBaseUrl + 'user/passwordChange' ,  { newPassword: this.newPassword1, oldPassword: this.loginData.password, username: this.loginData.username}, this.settings.httpOptions)
            .pipe(map(data => data)).subscribe(
            (data => {
                    if (data.status === 'success') {
                        this.pwr = 0;
                        this.changePassword = false;
                        this.changePwr(4);
                        this.loginData.password = '';
                        this.loginData.username = '';
                        setTimeout(() => {
                            document.getElementById('returnToLogin').focus();
                        }, 100);
                    }
                }
            ));
    } else {
        this.http.put<{ status: string, message: string }>(this.settings.restBaseUrl + 'user/password/reset' , { hash: this.hash, password: this.newPassword1 }, this.settings.httpOptions)
            .pipe(map(data => data)).subscribe(
            (data => {
                    if (data.status === 'success') {
                        this.pwr = 4;
                    } else {
                        this.messageContent = data.message;
                        this.message.open('messageBox');
                    }
                }
            ));
    }

    }

    startReport()
    {
        window.location.href = "index.html?session_id=#\/whistleblower/report"
    }

    startLogin() {
    let passwordComplexityValue = true;
    if (this.loginPasswordCheck.length === false || this.loginPasswordCheck.special === false || this.loginPasswordCheck.upper === false || this.loginPasswordCheck.lower === false || this.loginPasswordCheck.digits === false) {
        passwordComplexityValue = false;
    }


    const loginEncodedData = {
      username: this.loginData.username,
      password: this.loginData.password,
      passwordComplexity: passwordComplexityValue
    };
    this.http.post<{status: string, message: string, session_id: string }>(this.settings.restBaseUrl + 'user/login', JSON.stringify(loginEncodedData), this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        (data => {
          if (data[0].status === 'success') {
              const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', token: data[0].session_id }) };
              this.http.get<{status, route, startPageId }>(this.settings.restBaseUrl + 'user/entrypoint/' + data[0].userid, httpOptions)
                  .pipe(map(data2 => data2)).subscribe(
                  (data2 => {
                          let entrypoint = data2.route;
                          let destinationUrl = 'index.html?session_id=' + data[0].session_id;
                          if ( this.settings.docsearchcustomer !== '' && this.settings.docsearchcustomer != null && this.settings.docsearchcustomer != '0') {
                              entrypoint = '#/document/search';
                              destinationUrl += '&docsearchcustomer=' + this.settings.docsearchcustomer;
                          } else if (data2.startPageId == 2 || data2.startPageId == 4 || data2.startPageId == 21 || data2.startPageId == 22) {
                              destinationUrl += '#/core-frame/..%252Fapp%252Fview.php%253Fsession_id%253D' + data[0].session_id + '%2523%252F';
                              entrypoint = entrypoint.substring(2);
                          }
                          window.location.href = destinationUrl + entrypoint;
                    }
                  ));
          } else {
              if (data[0].status === 'changePassword') {
                  this.changePwr(3);
                  this.changePassword = true;
                  this.messageContent = 'Ihr Passwort entspricht nicht den geforderten Komplexitätsregeln. Bitte ändern Sie Ihr Passwort um diesen zu entsprechen.';
                  this.message.open('messageBox');

              } else {
                  if (data[0].status === 'error') {
                      if ( data[0].message === -6) {
                          this.messageContent = 'Das System befindet sich im Wartungsmodus. Bitte versuchen Sie es später nocheinmal!';
                          this.message.open('messageBox');
                      } else {
                          document.getElementById('password').blur();
                          if (data[0].message === -4) {
                              this.messageContent = 'Sie haben zu oft versucht sich mit einem flaschem Passwort anzumelden. Der Login wurde für 20 Sekunden gesperrt.';
                          } else if (data[0].message === -3) {
                              this.messageContent = 'Ihr Konto ist deaktiviert. Bitte wenden Sie sich an Ihren Systembetreuer..';
                          } else {
                              this.messageContent = 'Der Benutzername oder das Passwort ist falsch.';
                          }
                          this.message.open('messageBox');
                      }
                  }
              }
          }
        }
    ));
    }

    closeMessageBox() {
        this.messageContent = '';
        if (this.pwr === 3) {
            setTimeout(() => {
                document.getElementById('changePasswordOne').focus();
            }, 20);
        }
        if (this.pwr === '') {
            setTimeout(() => {
                document.getElementById('password').focus();
            }, 100);
        }

        this.message.close('messageBox');
    }

    checkForReturn($event: KeyboardEvent) {
    if ($event.which === 13) {
        if (this.changePassword) { this.saveNewPassword(); } else { this.startLogin(); }
        }
    }

    resetPassword() {
    this.http.put<{status: string, message: string}>(this.settings.restBaseUrl + 'user/password/reset',  { userOrEMail: this.userToResetPassword}, this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        (data => {
              if (data.status === 'success') {
                this.pwr = 2;
                this.userToResetPassword = '';
              } else {
                  this.messageContent = data.message;
                  this.message.open('messageBox');
              }
            }
        ));
    }

    changePwr(newPwr: number) {
        if  (   newPwr === 0  ) { this.pwr = ''; } else { this.pwr = newPwr; }
        if (newPwr === 0) {
            setTimeout(() => { document.getElementById('username').focus(); }, 200);
        }
        if (newPwr === 1) {
            setTimeout(() => { document.getElementById('idUserToResetPassword').focus(); }, 200);
        }
        if (newPwr === 3) {
            setTimeout(() => { document.getElementById('changePasswordOne').focus(); }, 200);
        }
    }
}
